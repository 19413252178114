import React from "react"
import { Link } from "gatsby"

class DefaultFooter extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <p>Amazing engine Co., Ltd.</p>
        </div>
      </div>
    )
  }
}

export default DefaultFooter
